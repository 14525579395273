import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Le tableau de bord du comité de Direction`}</h1>
    <h3>{`Le problème initial`}</h3>
    <p>{`Le comité de direction de la  compagnie aérienne `}<strong parentName="p">{`European Continental`}</strong>{` se déroule de manière mensuelle. Plusieurs directeurs présentent le résultat du mois précédent chacun dans son domaine de responsabilité: `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Les ventes`}</strong>{`: les résultats par canaux de distribution, les taux d'occupation des vols, l'activité en nombre de vols, passagers, kilomètres passagers, ... `}</li>
      <li parentName="ul"><strong parentName="li">{`Les opérations`}</strong>{`: nombre de retards et d'annulation par ligne et par causes, la consommation carburant, ... `}</li>
      <li parentName="ul"><strong parentName="li">{`La finance`}</strong>{`: rentabilité par lignes, par passager, par kilomètre, ...`}</li>
    </ul>
    <p>{`Les supports de présentation sont des feuilles Excel homogènes donnant par mois, trimestre et annuellement, l'objectif, le résultat courant, l'écart, le comparatif à la même période de l'année précédente, ...   `}</p>
    <h3>{`Quelles sont les difficultés de cette façon de faire ?`}</h3>
    <p>{`Tout est `}<strong parentName="p">{`à plat`}</strong>{` sans structuration. Certaines données sont redondantes entre les présentations (nombre de passagers). C'est une mosaïque de points de vues qui laisse à chacun le soin de piocher ce qu'il veut. Les relations de cause à effet sont masquées.`}<br parentName="p"></br>{`
`}{`Le tableau de bord unique est l'occasion de partager une même vision de la situation, hiérarchisée par les objectifs de l'organisation. `}</p>
    <h1>{`La solution mise en place`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.5521472392638%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHuxohFB//EABcQAQEBAQAAAAAAAAAAAAAAAAARARD/2gAIAQEAAQUCVeRMf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABYQAAMAAAAAAAAAAAAAAAAAABAgQf/aAAgBAQAGPwIVP//EABsQAAICAwEAAAAAAAAAAAAAAAABEWEhMUFR/9oACAEBAAE/IeCZt50yXgixKVH/2gAMAwEAAgADAAAAEPAP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAAMAAwAAAAAAAAAAAAAAAQARIUFh0f/aAAgBAQABPxDVa8wrVVFQo8oMHWwF9tsAj//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "avions preview",
          "title": "avions preview",
          "src": "/static/5bcaf81324ac00b00cb9804b075b9884/6aca1/avions_preview.jpg",
          "srcSet": ["/static/5bcaf81324ac00b00cb9804b075b9884/d2f63/avions_preview.jpg 163w", "/static/5bcaf81324ac00b00cb9804b075b9884/c989d/avions_preview.jpg 325w", "/static/5bcaf81324ac00b00cb9804b075b9884/6aca1/avions_preview.jpg 650w", "/static/5bcaf81324ac00b00cb9804b075b9884/2116d/avions_preview.jpg 877w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <center>
      <p><a parentName="p" {...{
          "href": "/Avions.pdf"
        }}><em parentName="a">{`Pour agrandir cliquer ici`}</em></a></p>
    </center>
    <br />
    <p>{`Plusieurs remarques sur ce tableau de bord très dense:`}</p>
    <ul>
      <li parentName="ul">{`Tout est présenté en une seule page A4. Photocopiable ou faxable en noir et blanc. La couleur est réservée pour attirer l'`}{`œ`}{`il vers les données critiques, mais sa signification survit au passage au monochrome. `}</li>
      <li parentName="ul">{`Les résultats et objectifs des différents indicateurs sont présentés de manière homogène même si le dépassement de l'objectif signifie une mauvaise performance. L'`}{`œ`}{`il est capable de localiser l'information à creuser immédiatement. `}</li>
      <li parentName="ul">{`Les données de la période courante sont explicites: sous forme numérique. La tendance des valeurs passées est juste évoquée sous forme de sparkline. `}</li>
      <li parentName="ul">{`Toutes les données parties d'un même ensemble sont triées de la plus élevée à la plus basse pour faciliter l'appréhension de la contribution relative de chacune.  `}</li>
      <li parentName="ul">{`Les retards et annulations sont présentés en bloc côte à côte. Ceci permet d'apprécier d'un regard la nuisance globale de chaque cause.   `}</li>
      <li parentName="ul">{`Au lieu de l'exhaustivité des données, l'accent est mis sur les 10 meilleures (comprendre pourquoi et généraliser si possible) et plus mauvaises performances (diagnostiquer et corriger rapidement). `}</li>
      <li parentName="ul">{`Sans être un dirigeant d'une compagnie aérienne, on ne résiste pas à l'impression d'être `}<strong parentName="li">{`aux commandes`}</strong>{`. Le tableau de bord fait que les buts de l'organisation sont `}<strong parentName="li">{`évidents`}</strong>{`. On a envie d'aller voir en dessous. Pourquoi la ligne Paris-Brest est-elle si peu fiable ? Quelle est l'augmentation du tarif passager moyen qui compense une augmentation de la parité Dollar/ Euro de 20% à marge constante ?`}</li>
    </ul>
    <br />
    <p><em parentName="p">{`Librement adapté d'un vieux concours de DashBoardSpy`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      